import React from 'react';
import LinkContainer from '../LinkContainer/LinkContainer';

export default function Home(props){


    return (
        <div className="Home">
           <div>
               Archive Link
           </div>

            <LinkContainer />
        </div>
    )
}